import React from 'react';
import type { FC } from 'react';
import Text from 'components/Text';
import { useQuery } from 'styles/breakpoints';

interface ProgressCountProps {
  total: number;
  count: number;
}

const ProgressCount: FC<ProgressCountProps> = ({ total, count }) => {
  const { isTablet } = useQuery();
  return (
    <Text type={isTablet ? 'bodyM500' : 'body500'} color="dark80">
      {count}/{total}
    </Text>
  );
};

export default ProgressCount;
