import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { LoadingPage, PrimaryButton, Seo, Text } from 'components';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import { usePageView, useQuizData } from 'utils/hooks';
import { normalizeStates } from 'utils/localization';
import TestFor from './components/TestFor';
import Header from 'components/Header';
import { tablet } from 'styles/breakpoints';
import Arrow from 'assets/icons/results/green-arrow-right.svg';
import Mark from 'assets/icons/results/mark.svg';
import Checkmark from 'assets/icons/results/checkmark.svg';
import ResultsProgress from 'components/ResultsProgress';

const ResultsMetabolism: FC = () => {
  const [loading, setLoading] = useState(false);

  const user = useSelector((state: AppState) => state.user);
  //   const dispatch = useDispatch();

  const { goToResultsSummary } = useRouter();

  usePageView({
    client_code: user.code,
    city: encodeURIComponent(
      user.geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: user.geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      user.geolocation?.iso_country || '',
      user.geolocation?.iso_state || '',
    ),
    gender: user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
    email: user.user?.email,
  });

  const handleClick = () => {
    setLoading(true);
    goToResultsSummary();
  };

  const data = useQuizData('resultsMetabolism');

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Outter>
        <Header color="novaPink" sticky={false} />
        <ResultsProgress
          step={data?.currentStep}
          totalSteps={data?.totalSteps}
        />
      </Outter>
      <Container>
        <InnerContainer>
          <TitleContainer>
            <Title dangerouslySetInnerHTML={{ __html: data?.title }} />
            {data?.subtitle && <Subtitle>{data?.subtitle}</Subtitle>}
          </TitleContainer>
          <ChartContainer>
            <LeftSide>
              <LeftTitle>
                <Mark /> {data?.nowTitle}
              </LeftTitle>
              <ListBox>
                {data?.nowList.map((item: string, index: number) => (
                  <ListItem
                    key={index}
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                ))}
              </ListBox>
            </LeftSide>

            <RightSide>
              <RightTitle>
                <Checkmark />
                {data?.afterTitle}
              </RightTitle>
              <ListBox>
                {data?.afterList.map((item: string, index: number) => (
                  <ListItem
                    key={index}
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                ))}
              </ListBox>
            </RightSide>
          </ChartContainer>
          <ButtonContainer>
            <ButtonStyled
              loading={loading}
              disabled={loading}
              onClick={handleClick}
            >
              <ArrowConatiner>
                <Arrow />
              </ArrowConatiner>
              {data?.buttonTitle}
            </ButtonStyled>
          </ButtonContainer>
        </InnerContainer>
      </Container>
    </>
  );
};

export default ResultsMetabolism;

const TitleContainer = styled.section`
  padding-bottom: 1.5rem;
`;

const Outter = styled.section`
  display: flex;
  flex-direction: column;
  /* position: sticky; */
  top: 0;
  z-index: 3;
`;

const Container = styled.section`
  padding: 3rem 1rem;
  background: #fef3f3;
  min-height: calc(100vh - 76px);
  @media ${tablet} {
    min-height: calc(100vh - 60px);
    padding: 1.5rem 1rem;
  }
`;

const InnerContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 26.25rem;
  margin: 0 auto;
`;

const Title = styled(Text)`
  color: #2a4b42;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  span {
    display: block;
    color: #ea7a74;
  }
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled(Text)`
  color: #2a4b42;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-top: 0.5rem;
  @media ${tablet} {
    font-size: 1rem;
  }
`;

const ArrowConatiner = styled.section`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 62.5rem;
  background: #fff;
  svg {
    width: 0.63538rem;
    height: 0.625rem;
  }
`;

const ButtonContainer = styled.section`
  width: 100%;
  max-width: 28.26rem;
  margin: 0 auto;
`;

const ButtonStyled = styled(PrimaryButton)`
  width: 100%;
  div {
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }
`;

const ChartContainer = styled.section`
  display: flex;
  width: 100%;
  margin-bottom: 2.625rem;
  @media ${tablet} {
    margin-bottom: 1rem;
  }
`;

const LeftSide = styled.section`
  width: 100%;
  display: flex;
  padding: 2rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 0.75rem 0rem 0rem 0.75rem;
  border: 1px solid #ea7a74;
  border-right: 0;
  background: #ea7a74;
  @media ${tablet} {
    gap: 1rem;
    padding: 1.5rem 0.45rem 1.5rem 0.5rem;
  }
`;

const RightSide = styled.section`
  width: 100%;
  display: flex;
  padding: 2rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 0rem 0.75rem 0.75rem 0rem;
  border: 1px solid #05a56f;
  border-left: none;
  background: #05a56f;
  @media ${tablet} {
    gap: 1rem;
    padding: 1.5rem 0.45rem 1.5rem 0.5rem;
  }
`;

const LeftTitle = styled(Text)`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #fff;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  @media ${tablet} {
    font-size: 1rem;
    gap: 0.375rem;
  }
`;

const RightTitle = styled(LeftTitle)``;

const ListBox = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  width: 100%;
  @media ${tablet} {
    gap: 1rem;
  }
`;

const ListItem = styled(Text)`
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  span {
    display: block;
  }
  @media ${tablet} {
    font-size: 0.75rem;
    span {
      display: inline-block;
    }
  }
`;
